/**
  * JS pour le projet CH Cognac
  * @author: ssorin - <ssorin@dwdlc.com>
  * v1.0 2022/06
  */

var app = window.app = {

    /** ################################
      * CALL FUNCTIONS
      * ################ */

    // General functions
    init: function () {
        // this.checkup('init');
        this.toggleNav();
        this.generalSearchToggle();
        this.popin();
        this.checkHighContrastMode();
    },

    // Pages functions
    home: function () {
        this.checkup('home');
        this.directorySearch();
    },
    directory: function () {
        this.tabInit();
        this.directorySearch();
    },
    accessibility: function () {
        this.checkBtnHighContrastMode();
    },


    /** ################################
      * FUNCTIONS
      * ################ */
    /**!
    Check if app.js is working
    */
    checkup: function (fnName) {
      console.log("Page" + fnName + ": Everything is working !");
    },

    tabInit: function () {
      $( "#tabs" ).tabs({
        active: false,
        collapsible: true
      });
    },

    /**!
    Navigation Button Toggle class
    */
    toggleNav: function () {
        // Define targets by their class or id
        let button = document.querySelector('.burger-button');
        let target = document.querySelector('nav.mainNav');
        let mainBloc = document.querySelector('main');
        let footerBloc = document.querySelector('footer');
        // click-touch event
        if ( button ) {
          button.addEventListener('click',
          function (e) {
                button.classList.toggle('is-active');
                target.classList.toggle('mainNav--is-opened');
                mainBloc.classList.toggle('hidden');
                footerBloc.classList.toggle('hidden');
            e.preventDefault();
          }, false );
        }
    },

    popin: function() {
        this.popinOpen();
        this.popinClose();
    },

    popinOpen: function () {
        let button = document.querySelector(".popin-urgence");
        if ( button ) {
          button.addEventListener('click', function (e) {setPopinAria();}, false );
        }
    },
    popinClose: function () {
        let button1 = document.querySelector(".sp-back");
        let button2 = document.querySelector(".sp-close");

        if ( button1 ) {
          button1.addEventListener('click', function (e) {removePopinAria();}, false );
        }
        if ( button2 ) {
          button2.addEventListener('click', function (e) {removePopinAria();}, false );
        }
    },


    /**!
    General search form - Display the search form
    */
    generalSearchToggle:function () {
        let searchButton = document.querySelector('.search__button');
        let searchForm = document.querySelector('.searchForm');

        if ( searchButton ) {
          searchButton.addEventListener('click',
          function (e) {
              searchForm.classList.toggle('hidden');
          e.preventDefault();
          }, false );
        }
    },

    /**!
    Check if high contrast theme is on or off
    */
    checkHighContrastMode:function () {
        let element = document.body;
        let HighContrastCookie = Cookies.get("HighContrastMode")

        if (HighContrastCookie == 'An') {
            element.classList.add("accessibility");
        } else {
            element.classList.remove("accessibility");
        }
    },

    /**!
    Display "oui" if high constrast is on and "non" if off
    */
    checkBtnHighContrastMode:function () {
        let btn = document.getElementById("HighContrastButton");
        let HighContrastCookie = Cookies.get("HighContrastMode")

        if (btn) {
            if (HighContrastCookie == 'An') {
                btn.innerHTML = "oui"
            }
        }
    },



    /**!
    Fonctions pour les bar de recherche de l'annuaire
     - searchBarClean
     - searchBarInit
     - autocompleteSearchBar
     - fetchSuggestionsList
     - fillSuggestionsList
     - searchBarSelectSuggestion
     - searchBarCloseSuggestions
    */

    directorySearch: function () {
        this.searchBarClean();
        this.searchBarInit();
    },

    searchBarClean: function () {
        window.sessionStorage.removeItem('search_suggestions_list')
        window.sessionStorage.removeItem('staff_suggestions_list')
        window.sessionStorage.removeItem('services_suggestions_list')
        window.sessionStorage.removeItem('specialities_suggestions_list')
    },

    searchBarInit: function () {
        // window.sessionStorage.removeItem('search_suggestions_list')

        let body = $("body")

        $('.searchbar-autocomplete').on("input", function (e) {
            app.autocompleteSearchBar(e);
        })

        body.on('click','.suggestion-choice', function (e) {
            app.searchBarSelectSuggestion(e);
        })

        body.on('click', function (e) {
            app.searchBarCloseSuggestions(e);
        })
    },

    // If the suggestions list is not stored in sessionStorage, will call fetchSuggestionsList function and store them
    // parameter : input event
    autocompleteSearchBar: function (e) {
        let searchbar_input = e.currentTarget;
        let key = 'search_suggestions_list'
        if (searchbar_input.id === 'staff_searchbar') {
             key = 'staff_suggestions_list'
        }
        if (searchbar_input.id === 'specialities_searchbar') {
             key = 'specialities_suggestions_list'
        }
        if (searchbar_input.id === 'services_searchbar') {
             key = 'services_suggestions_list'
        }

        let suggestions_list = JSON.parse(window.sessionStorage.getItem(key))
        if(!suggestions_list) {
            this.fetchSuggestionsList($(e.currentTarget).data("url"))
                .then(response => {
                    window.sessionStorage.setItem(key,JSON.stringify(response))
                    this.fillSuggestionsList(e,response)
                })
        }
        else {
            this.fillSuggestionsList(e,suggestions_list)
        }
    },

    // Fetches and return the suggestions list
    // parameter : search url
    fetchSuggestionsList: async function(search_url) {
        return $.ajax({
            url: search_url,
            success: function (response) {
                return response
            },
        })
    },

    // Compares the suggestions list with the search bar input value and return the filtered values
    // parameter : input event, autocomplete suggestions list
    fillSuggestionsList: function (e,suggestions_list) {
        let searchbar_input = e.currentTarget;
        let searchbar_wrapper = $(searchbar_input).closest(".searchbar-input-wrapper");
        let searchbar_autocomplete_wrapper = $(searchbar_wrapper).find(".searchbar-autocomplete-wrapper");
        let searchbar_autocomplete_results = $(searchbar_wrapper).find(".searchbar-autocomplete-results");

        searchbar_autocomplete_wrapper.css('display', 'none');
        searchbar_autocomplete_results.empty();

        let filtered_suggestions = [];
        let suggest_term = '';

        for (let i = 0; i < suggestions_list.length; i++) {
            if (suggestions_list[i][0].toUpperCase().indexOf($(searchbar_input).val().toUpperCase()) !== -1) {
                filtered_suggestions.push(suggestions_list[i])
            }
        }

        searchbar_autocomplete_wrapper.css('display', 'block');
        if(filtered_suggestions.length) {
            for (let i = 0; i<filtered_suggestions.length; i++) {
                suggest_term = filtered_suggestions[i][0];

                if (filtered_suggestions[i][2]) {
                    suggest_term = filtered_suggestions[i][0] + " <em>(" + filtered_suggestions[i][2] + ")</em>";
                    if (filtered_suggestions[i][3]) {
                        suggest_term = filtered_suggestions[i][0] + " <em>(" + filtered_suggestions[i][3] + " - " + filtered_suggestions[i][2] + ")</em>";
                    }
                }

                searchbar_autocomplete_results.append('<li class="suggestion-choice" data-suggest="'+filtered_suggestions[i][1]+'">' + suggest_term + '</li>');
            }
        }
        else {
            let no_result = $(searchbar_input).data("noresult");
            searchbar_autocomplete_results.append('<li>'+ no_result +'</li>');
        }
    },

    // Fills the search bar input with the clicked suggestion value
    // parameter : input event
    searchBarSelectSuggestion: function (e) {
        let suggestion_choice = e.currentTarget;
        let searchbar_wrapper = $(suggestion_choice).closest(".searchbar-input-wrapper");
        let searchbar_input = $(searchbar_wrapper).find(".searchbar-autocomplete");
        let searchbar_input_hidden_id = $("#"+searchbar_input[0].id+"_suggest_id")
        let searchbar_autocomplete_wrapper = $(searchbar_wrapper).find(".searchbar-autocomplete-wrapper");
        let searchbar_autocomplete_results = $(searchbar_wrapper).find(".searchbar-autocomplete-results");

        $(searchbar_input[0]).val($(suggestion_choice).text())
        searchbar_input_hidden_id.val(suggestion_choice.dataset.suggest);

        searchbar_autocomplete_wrapper.css('display', 'none')
        searchbar_autocomplete_results.empty()
    },

    // Closes the suggestions list if the user clicks outside it
    // parameter : input event
    searchBarCloseSuggestions: function (e) {
        let searchbar_autocomplete_wrappers = $(".searchbar-autocomplete-wrapper")

        searchbar_autocomplete_wrappers.each(function(index,el) {
            if(!$(el).is(e.target) &&
                $(el).has(e.target).length === 0) {
                let display = $(el).css('display')
                if (display === 'block') {
                    $(el).css('display','none')
                }
            }
        });
    },

};

/**
 *
 * Global function
 *
 */
function setPopinAria() {
    let target = document.getElementById("popin-urgence");
    target.removeAttribute('aria-hidden');
    target.setAttribute('aria-label', 'modale')
}
function  removePopinAria() {
    let target = document.getElementById("popin-urgence");
    target.removeAttribute('aria-label');
    target.setAttribute('aria-hidden', 'true')
}

function toggleHighContrastMode() {
        let element = document.body;
        let HighContrastCookie = Cookies.get("HighContrastMode")
        let HighContrastMode = false;
        let button = document.getElementById("HighContrastButton");

        if (HighContrastCookie == 'An') {
          HighContrastMode = true;
        }
        HighContrastMode = !HighContrastMode
        // console.log("Dark mode: " + HighContrastMode);

        if (HighContrastMode) {
            Cookies.set("HighContrastMode", "An");
            element.classList.add("accessibility");
            button.innerHTML = "oui"

        } else {
            Cookies.remove("HighContrastMode");
            element.classList.remove("accessibility");
            button.innerHTML = "non"
        }
}

/**
 *
 * Wait for DOM ready and init() the app
 *
 */

$(document).ready(function () {
    // DOM is ready
    app.init();

    if($("#home").length) {
        app.home();
    }
    if($("#directory").length) {
        app.directory();
    }
    if($("#accessibility").length) {
        app.accessibility();
    }
});
